.pdf-export-details {
  margin-top: 10px;

  .pdf-export-title {
    margin-top: 8px;
    margin-bottom: 4px;
  }

  .questioner-title {
    width: 100%;
  }

  .date-answered {
    font-size: 12px;
  }

  ul {
    list-style: inside;
    margin-bottom: 5px;

    li {
      padding-left: 10px;
    }
  }
}
