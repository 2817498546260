@import './diagnostic-question';
@import './cognetive-chart-item';
@import './brain-dementia';
@import './brain-points-position';
@import './thermometer';


// Tabs config styles
.diagnostic_tabs-container {
  padding: 20px 16px 0;
  width: 100%;
  margin: {
    left: auto;
    right: auto;
  };
  .top-level-tabs {
    margin-bottom: 24px;
  }
  .second-level-tabs {
    margin-bottom: 10px;
  }
  .selector-holder {
    flex: 0 0 100%;
  }
  @media screen and (min-width: 768px) {
    .top-level-tabs {
    max-width: 640px;
    margin-bottom: 18px;
    }
    .second-level-tabs {
      max-width: 600px;
      margin-bottom: 0;
    }
    .selector-holder {
      flex: 0 0 auto;
    }
  }
  @media screen and (min-width: 1200px) {
    max-width: 1200px;
    padding: 0;
  }
}

// User Sidebar
.diagnostic_card {
  p {
    font-size: 10px;
    margin: 0;
  }
  .label {
    color: $text-color_secondary;
    font-size: 10px;
    font-weight: 600;
    margin-bottom: 4px;
    text-transform: uppercase;
  }
  .user_personal-info {
    text-align: center;
  }
  .card_avatar {
    background: $primary-color;
  }
  .avatar-icon {
    height: 18px;
    width: 16px;
    fill: $white;
  }
  .user_metrics {
    margin-bottom: 16px;
  }
  .user_metrics_item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .user_email {
    font-size: 12px;
  }
  .user-info {
    color: $text-color_primary;
    text-align: center;
    p {
      font-size: 14px;
      font-weight: 600;
      &.light {
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 5px;
      }
    }
  }

.user-info .twoBtnDiv {
  display:flex;
  margin-right:10px;
  margin-left:10px;
}

  .user_metrics_item {
    > span {
      font-size: 10px;
      margin-bottom: 5px;
      &.value {
        font-size: 20px;
      }
    }
  }
  .user_locale {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    dt {
      color: $text-color_secondary;
      font-size: 10px;
      font-weight: 600;
      margin-bottom: 4px;
    }
    dd {
      font-size: 14px;
    }
  }
  .reviews_list {
    max-height: 200px;
    overflow-y: auto;
    .review-title {
      font-size: 14px;
      font-style: italic;
    }
    .review-date {
      font-size: 12px;
    }
  }
}

// Sidebar Comments Box
.card_comment {
  .card_comment-box {
    padding: 22px 0;
    &:not(:last-child) {border-bottom: 1px solid $inputs-border-color;}

  }
  .comment_header {
    margin-bottom: 8px;
  }
  .comment_header_title {
    font-size: 14px;
    font-weight: 600;
    color: $text-color_secondary;
    margin-bottom: 8px;
  }
  .download-pdf-title {
    color: $text-color_additional;
    font-weight: normal;
  }
  .download-pdf-date {
    font-size: 10px;
    color: $text-color_additional;
    font-weight: normal;
  }
  .comment_header_icon,
  .comment_edit_btn{
    width: 10px;
    height: 12px;
    fill: $text-color_additional;
    margin-right: 8px;
  }
  .comment_edit_btn {
    width: 16px;
    height: 16px;
  }
  .comment_header_author {
    font-size: 12px;
    color: #757575;
  }
  .comment_content {
    color: $text-color_primary;
    font-size: 14px;
    p {
      color: inherit;
      font-size: inherit;
      margin: 0;
    }
    .comment_header_author {
      margin-bottom: 16px;
    }
    &:not(:last-child) {
      border-bottom: 1px solid  $inputs-border-color;
      padding-bottom: 16px;
      margin-bottom: 16px;
    }
  }
  .card_footer {
    border-top: 1px solid  $inputs-border-color;
  }
  .add-comment-btn {
    color: $primary-color;
  }

}

.card_comment-box_textarea {
  padding-top: 24px;
  h3 {
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 12px;
  }
  select {
    margin-bottom: 10px;
  }
  textarea {
    border-radius: 4px;
    height: 90px;
    margin-bottom: 10px;
  }
  .comment_btn {
    color: $primary-color;
    font-size: 14px;
    padding: 8px 14px;
    text-transform: uppercase;
    &--filled {
      border-radius: 32px;
      background: $primary-color;
      color: $white;
    }
  }
  @media screen and (min-width: 748px) {
    select {
      max-width: 216px;
    }
  }
}

.card-box {
  &--comments {
    .card_body { max-height: 220px; }
  }
  &--history {
    .card_body { max-height: 440px; }
  }
}

.diagnostic_test-box {
  margin-bottom: 10px;
  transition: box-shadow ease .2s;

  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}
.diagnostic_test-box, .checkbox-modal-item {
  .cog-test-wrapper {
    //flex-wrap: nowrap;
  }
  .test-title {
    font-size: 16px;
    font-weight: 500;
    color: $black;
    margin-bottom: 8px;
    word-break: break-word;
  }
  .test-date {
    font-size: 12px;
    color: $light-grey;
  }
  .test-status_box {
    font-size: 12px;
    font-weight: 600;
    color: $text-color_secondary;
    border: 2px solid;
    text-align: center;
    margin-bottom: 8px;
    min-width: 136px;
    display: flex;
    &--not-completed { color: $error-color; }
  }

  .status_box {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    padding: 0;
    .exit-text {
      border-radius: 20px;
      padding:5px;
      font-weight: 900;
      text-align: end;
      margin-top: 30px;
      font-size: 12px;
      background-color: #ff0000;
      color: #ffffff;
    }
    .status-holder {
      display: flex;
      align-items: center;
      position: relative;
      .status-text {
        font-size: 13px;
        color: #465b64;
      }
      .circle-checked {
        position: absolute;
        right: 2px;
        fill: #fff;
      }
      .circle {
        display: inline-block;
        margin-left: 12px;
        height: 24px;
        width: 24px;
        border-radius: 50%;

        &--green {
          background-color: #699c2b;
        }

        &--yellow {
          background-color: #F2C94C;
        }
        &--red {
          background-color: #f24c4c;
        }

        &--grey {
          background-color: #B2B2B2
        }
      }
    }
  }
  .status_text {
    flex: 1 1 0px;
    padding: 4px;
    text-align: center;
    white-space: nowrap;
  }
  .status_selector {
    flex: 0 0 20px;
    color: inherit;
    border-left: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .test-results {
    font-size: 12px;
    color: $light-grey;
    text-align: right;
  }
}
.cog-test-box {
  padding: 10px 16px!important;
  .results-block {
    margin-top: 15px;
    position: relative;
    .extra-label {
      position: absolute;
      bottom: 5px;
      left: -50px;
      font-size: 10px;
    }
  }
}

.diagnostic_card-title {
  display: flex;
  align-items: center;
  padding-bottom: 24px;
  &.bordered {
    border-bottom: 1px solid $inputs-border-color;
  }
  h3 {
    font-size: 16px;
    font-weight: 600;
  }
  svg {
    margin-right: 10px;
    height: 18px;
    width: 20px;
  }
}

.diagnostic_family-info {
  margin-bottom: 24px;
  dt {
    font-size: 10px;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
  dd { font-size: 14px; }
}

.diagnostic_test_summary {
  word-break:normal;
  &:not(:last-child) { margin-bottom: 8px; }
  &--outlined {
    border: 1px solid $border-color;
    border-radius: 6px;
    padding: 14px;
  }
  cursor: pointer;

  .common-title{
    color: black;
  }
}

.diagnostic_test_body {
  margin-bottom: 8px;
}

.survey-info-popup {
  transform: translate(-70%);
  top: -4.5rem;
  div {
    min-height: 60px;
    text-align: center;
  }
}

.transform-0 {
  transform: translate(0);
}

.cognitive-test_section {
  margin-bottom: 20px;
}

.test_description_title {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 16px;
}

.test_summary-points {
  .bordered-box {
    padding: 20px;
    border: 1px solid $border-color;
  }
  .points-item { max-width: 25%; }
  dl {
    color: $light-grey;
    .bold-title { color: #000; }
    &:not(.decreased-fz) {
      dt {
        font-size: 14px;
        line-height: 19px;
      }
      dd {
        font-size: 12px;
        line-height: 16px;
      }
    }
    dt {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
    }
    dd {
      font-size: 10px;
      line-height: 14px;
    }
  }
}

.exercise_category {
  margin-bottom: 16px;
  .diagnostic_question_heading {
    color: $text-color_secondary;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 12px;
  }
}

.support_meetings {
  h4 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 14px;
  }
  table {
    &:not(:last-child) { margin-bottom: 36px; }
  }
}

.refresh-icon {
  width: 20px;
  height: 20px;
  display: block;
  transition: all 200ms ease-in;
}
