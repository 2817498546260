.hide {
  display: none;
}

.t {
  &_uppercase { text-transform: uppercase; }
  &_right { text-align: right; }
  &_left {text-align: left;}
  &_center {text-align: center;}
  &_size_16 {
    font-size: 16px !important;
  }
  &_bold {
    font-weight: 600;
  }
}

.overflow-wrap--break {
  overflow-wrap: break-word;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  &:before {
    content: "";
    display: block;
  }
}


.table--colored {
  th {
    color: #4a4a4a;
    font-size: 10px;
    text-transform: uppercase;
    padding: 4px 8px;
  }
  tr {
    text-align: left;
  }
  tbody {
    tr {
      &:nth-child(2n - 1) {
        background: rgba(196, 196, 196, 0.15);
      }
    }
  }
  td {
    color: #424242;
    padding: 4px 8px;
    font-size: 14px;
    line-height: 1.25;
  }
  @media screen and (min-width: 576px) {
  }
}

.dates_range {
  li {
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
  }
  span {
    display: inline-block;
    max-width: 30px;
  }
}

.calendar_legend-list {
  color: $text-color_primary;
  font-size: 14px;
  line-height: 1;
  li {
    padding-left: 24px;
    margin-bottom: 8px;
    position: relative;
    &:before {
      content: '';
      border-radius: 2px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 15px;
      width: 15px;
    }
    &:nth-child(1) {
      &:before { background: $primary-color; }
    }
    &:nth-child(2) {
      &:before {
        background: #def0fd;
        border: 1px solid #59B9FF;
      }
    }
    &:nth-child(3) {
      &:before { background: #E1E7EA; }
    }
    &:nth-child(4) {
      &:before {
        background: #e5e5e5;
        border: 1px solid #70868f;
      }
    }
  }
}

.c-pointer { cursor: pointer; }

.commot-list {
  font-size: 14px;
  &:not(:last-child) { margin-bottom: 20px; }
  li:not(:last-child) { margin-bottom: 8px; }
}

.common-title {
  font-size: 12px;
  color: #979797;
  margin-bottom: 8px;
}

.no-borders {
  border: none !important;
}

.document-icon {
  height: 18px;
  width: 18px;
  fill: #546e7a;
  &.green {
    fill: #71aa33;
  }
}

.document-title {
  margin: 6px;
}

.text-center {
  text-align: center;
}

.margin-me {
  &--top {
    margin-top: 6px;
  }
  &--left {
    margin-left: 6px;
  }
  &--right {
    margin-right: 6px;
  }
  &--btm {
    margin-bottom: 6px;
  }
}

.app-version {
  width: 100%;
  &.clickable:hover {
    font-weight: 600;
    color: black;
    cursor: pointer;
  }
  p {
    margin: 0;
  }
}

.word-wrap-break {
  word-wrap: break-word;
}

.p-relative {
  position: relative !important;
}

.p-absolute {
  position: absolute !important;
}

.expand-icon {
  height: 18px;
  width: 18px;
  fill: #546e7a;
  transition: transform 150ms ease-in;
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
  &--left {
    transform: rotateZ(0deg);
  }
  &--right {
    transform: rotateZ(180deg);
  }
  &--top {
    transform: rotateZ(90deg);
  }
  &--bottom {
    transform: rotateZ(-90deg);
  }
}

.pt-20 {
  &--padding-top {
    padding-top:20px;
  }
  &--padding-bottom {
    padding-bottom:20px;
  }
}

.pt-md-0 {
  @media screen and (min-width: 768px) {
    padding-top: 0;
  }
}

.text-sm {
  font-size: 14px;
}
